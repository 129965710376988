.inner-banner {
  height: 47vh;
  @include padding-x;
  padding-bottom: 135px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background-image: url(../img/inner-banner.png);
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // position: relative;
  z-index: 0;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    height: 40vh;
    padding-bottom: 105px;
  }

  @media (max-width: 820px) {
    height: 32vh;
    padding-bottom: 75px;
  }

  @media (max-width: 560px) {
    align-items: center;
  }

  @media (min-height: 1160px) {
    height: 17vh;
  }

  &-title {
    font-weight: 700;
    // font-size: 55px;
    font-size: 35px;
    line-height: 68px;
    // color: #fff;
    color: black;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 40%;
    left: 10%;

    @media (max-width: 1680px) {
      font-size: 48px;
      line-height: 52px;
    }

    @media (max-width: 1280px) {
      font-size: 42px;
      line-height: 47px;
    }

    @media (max-width: 1024px) {
      top: 15%;
      font-size: 38px;
      line-height: 42px;
    }

    @media (max-width: 820px) {
      top: 16%;
      font-size: 34px;
      line-height: 40px;
    }

    @media screen and (max-width: 375px) and (max-height: 667px) {
      top: 28%;
    }
  }
}
