.news {
  position: relative;

  &-grid {
    display: grid;
    grid-template-columns: 35% 65%;
    height: 100%;
    border: 1px solid #38495d;

    @media (max-width: 560px) {
      grid-template-columns: 1fr;
    }
  }

  &-banner {
    height: 100%;
  }

  .news-filter-bar {
    background: #f4f4f4;
    display: flex;
    justify-content: center;
    padding-block: 15px;

    @media (max-width: 1880px) {
      padding-inline: 450px;
    }

    @media (max-width: 1680px) {
      padding-inline: 425px;
    }

    @media (max-width: 1440px) {
      @include padding-x;
    }

    @media (max-width: 820px) {
      background: none;

      .btn {
        font-size: 14px;

        @media (max-width: 560px) {
          height: 100%;
        }
      }
    }

    @media (max-width: 560px) {
      margin-top: 20px;
      display: grid;
      grid-template-rows: auto auto;

      .btn {
        font-size: 10px;
      }

      .filter-item {
        &:first-child {
          grid-row: 1; /* Specifies the first div to be placed in the first row */
        }

        grid-row: 2;

        @media (max-width: 560px) {
          height: 100%;
        }
      }
    }

    .filter-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 29px;
      text-transform: uppercase;
      // padding-block: 22px;
    }

    .filter-btn {
      width: auto;
    }

    .filter-title {
      line-height: 20px;
    }
  }
  .detail-tit {
    margin: 0rem 6rem 2rem 2rem;
    .detail-title-wrap {
      text-align: left;
      &::before {
        width: 100%;
      }
      .page-heading-wrap {
        padding-inline: 0;
        padding: 0;
        .page-heading {
          font-size: 25px;
          text-transform: inherit;
        }
      }
    }
  }

  .highlight-text {
    p {
      font-size: 12px;
      margin-bottom: 0;
    }
    .date-color {
      color: $secondary;
    }
  }
  .read-more {
    margin-top: 1.5rem;
    margin-left: 2rem;
    a {
      letter-spacing: 4px;
      color: $primary;
      font-weight: 500;
      font-size: 15px;
    }
  }

  .years {
    .year-title {
      font-weight: bold;
    }
    .small-info-text {
      font-size: 15px;
      margin-top: 1.5rem;
    }
  }

  .body_insights {
    @include padding-x-140;
    padding-block: 110px;

    @media (max-width: 1024px) {
      padding-block: 65px;
    }

    @media (max-width: 560px) {
      padding-top: 0;
      padding-bottom: 40px;
    }

    .insight_box {
      border: #433185 1px;
    }

    img {
      height: 100%;
    }

    .insights_details {
      padding: 25px;

      span {
        cursor: pointer;
        font: normal normal normal 12px/18px Muli;
        letter-spacing: 0px;
        color: #433185;
        text-transform: uppercase;
        opacity: 1;
      }
      h3 {
        font: normal normal bold 22px/26px Muli;
        letter-spacing: 0px;
        color: #1c1d1e;
        opacity: 1;

        @media (max-width: 560px) {
          padding-block: 10px;
        }
      }
      p {
        padding-top: 5px;
        font: normal normal normal 14px/22px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 0.6;
      }
    }
  }
  .box_bottom_insights {
    background: transparent url(../img//techBanner.png) 0% 0% no-repeat
      padding-box;
    opacity: 1;
    height: 144px;
    right: 67px;
    position: absolute;
    z-index: 3;
    top: -92px;

    h3 {
      text-align: center;
      font: normal normal bold 18pt/26pt Muli;
      letter-spacing: 0pt;
      color: #ffffff;
      opacity: 1;
      padding: 45px;
    }
  }
}
