.banner {
  &-wrap {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    @media (max-width: 1440px) {
      height: 87vh;
    }

    @media (min-height: 1320px) {
      height: 37vh;
    }

    @media (max-width: 1280px) {
      height: 83vh;
    }

    @media (max-width: 1024px) {
      height: 66vh;
    }

    @media (max-width: 820px) {
      height: 51vh;
    }
    @media (max-width: 720px) {
      height: 40vh;
    }

    @media (max-width: 620px) {
      height: 70vh;
      margin-top: 0;
      z-index: 1;
    }
    @media (max-width: 420px) {
      height: 40vh;
      margin-top: 0;
      z-index: 1;
    }
    @media (max-width: 380px) {
      height: 52vh;
      margin-top: 0;
      z-index: 1;
    }
    @media (max-width: 290px) {
      height: 42vh;
      margin-top: 0;
      z-index: 1;
    }
  }

  &-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    z-index: 1;

    @media (max-width: 1600px) {
      width: 100%;
    }

    @media (max-width: 1440px) {
      width: auto;
    }
  }

  &-svg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;

    @media (max-width: 820px) {
      width: auto;
      height: 65%;
      left: -140px;
    }

    @media (max-width: 560px) {
      height: 55%;
      left: -17%;
    }
  }

  &-content {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-bottom: 125px;
    padding-top: 220px;
    height: 100%;
    position: relative;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(1, 25, 52, 0.2) 0%,
      rgba(1, 25, 52, 0.3) 100%
    );

    @media (max-width: 992px) {
      padding-top: 140px;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 52px;
    line-height: 62px;
    position: relative;
    padding-top: 60px;
    color: $white;

    @media (max-width: 1600px) {
      padding-top: 50px;
      font-size: 42px;
      line-height: 52px;
    }

    @media (max-width: 992px) {
      padding-top: 30px;
      font-size: 30px;
      line-height: 46px;
    }

    @media (max-width: 560px) {
      font-size: 22px;
      line-height: 32px;
    }

    &::before {
      position: absolute;
      top: 0;
      width: 115px;
      height: 1px;
      content: "";
      background: $primary;
      left: calc(50% - 57px);
    }
  }

  &-text {
    font-size: 52px;
    line-height: 72px;
    color: $secondary;
    font-weight: 700;

    @media (max-width: 1600px) {
      font-size: 32px;
      line-height: 46px;
    }

    @media (max-width: 992px) {
      font-size: 26px;
      line-height: 37px;
    }

    @media (max-width: 560px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &-logo {
    margin-bottom: 60px;

    @media (max-width: 1600px) {
      margin-bottom: 50px;
      width: 350px;
    }

    @media (max-width: 992px) {
      margin-bottom: 30px;
      width: 225px;
    }
  }
}
.advantage-video {
  max-width: 100%;
}

@media (max-width: 1600px) {
  .bottom-card {
    height: 247px !important;
  }
}
