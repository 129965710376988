.team {
  &-box {
    position: relative;
    height: 385px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 320px;

    @media (max-width: 1600px) {
      height: 325px;
      width: 100%;
    }

    @media (max-width: 992px) {
      height: 298px;
    }

    @media (max-width: 820px) {
      height: 247px;
    }

    @media (max-width: 560px) {
      height: 256px;
    }

    &:hover {
      .team-about {
        transform: translate(-9px, -10px);
      }
    }
  }

  &-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    width: 95%;
    padding: 10px;
    overflow: hidden;

    img {
      height: 100%;
    }
  }

  &-about {
    position: relative;
    height: 50%;
    padding: 30px;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(
      180deg,
      rgba(1, 25, 52, 0) 0%,
      rgba(1, 25, 52, 0.8) 50.53%,
      #011934 100%
    );
    transform: translateY(100%);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &-name {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: $white;
    margin-bottom: 10px;

    @media (max-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-position {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: $white;
  }

  &-section {
    padding-bottom: 150px;

    @media (max-width: 992px) {
      padding-bottom: 80px;
    }

    .slick-prev,
    .slick-next {
      transform: translate(0);
      top: 100%;
      width: 320px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $gray-200;

      @media (max-width: 1600px) {
        width: 280px;
        height: 75px;
      }

      @media (max-width: 1440px) {
        width: 290px;
        height: 85px;
      }

      @media (max-width: 992px) {
        width: 195px;
        height: 65px;
      }

      @media (max-width: 820px) {
        width: 160px;
        height: 50px;
      }

      @media (max-width: 560px) {
        width: 70px;
      }

      &::before {
        content: none;
      }
    }

    .slick-prev {
      right: 850px;
      left: auto;

      @media (max-width: 1600px) {
        right: 770px;
      }

      @media (max-width: 1440px) {
        right: 775px;
      }

      @media (max-width: 992px) {
        right: 575px;
      }

      @media (max-width: 820px) {
        right: 460px;
      }

      @media (max-width: 560px) {
        right: 290px;
      }
    }

    .slick-next {
      right: 0;
    }
  }
}

.team-btn {
  margin-right: 320px;
  float: right;
  width: 530px;
  height: 100px;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 25px;
  color: $white;
  background: $secondary;
  justify-content: center;

  @media (max-width: 1600px) {
    height: 75px;
    width: 490px;
    margin-right: 280px;
  }

  @media (max-width: 1440px) {
    width: 485px;
    height: 85px;
    margin-right: 290px;
  }

  @media (max-width: 992px) {
    width: 380px;
    height: 65px;
    margin-right: 195px;
    font-size: 15px;
    line-height: 20px;
  }

  @media (max-width: 820px) {
    width: 300px;
    height: 50px;
    margin-right: 160px;
  }

  @media (max-width: 560px) {
    width: 220px;
    margin-right: 70px;
  }

  svg {
    transition: all 0.3s ease;
    margin-left: 10px;
    transform: translateX(0);
  }

  &:hover {
    color: $white;

    svg {
      transform: translateX(10px);
    }
  }
}
