.modal {
 display: block !important;
 pointer-events: none;
 background: #00000057;
 z-index: 999;
 width: 100%;
 height: 100%;
 // height: 20%;
 // position: fixed;
 // top: auto;
 // right: auto;
 // left: auto;
 // bottom: 0;

 .modal-content {
  padding: 1rem 5px 2rem 5px;
  background: #fff;
  justify-content: center;

  .wrapper {
   width: 100%;
   height: 100%;
   text-align: right;

   .spinner-border {
    width: 10rem;
    height: 10rem;
   }

   img {
    margin-bottom: 2rem;
   }

   p {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   }

   .img-wrap {
    width: 100%;
    height: 100%;

   }

   .meta-button {
    padding: 28px 32px 28px 32px;
    cursor: pointer;
   }

   .meta-connect {
    background-color: #2D2A3D;
    // width: 100%;
    border-radius: 8px;
    padding: 10px 0px 10px 0px;
   }

   .meta-logo {
    width: 25px;
    padding: 12px 0px 12px 0px;
    margin-top: auto;
    margin-bottom: auto;
   }

   .meta-logo-arrow {
    width: 8px;
    margin-top: auto;
    margin-bottom: auto;
   }

   h4 {
    margin-top: 2rem;
   }

   .connect-rinkeby {
    margin-bottom: 0;
   }

   ._link {
    a {
     color: #E85F14;
    }
   }
  }
 }

  .modal-dialog {
   max-width: 1000px;
   ;
   margin: 1.75rem auto;
  }

 @media (max-width: 1100px) {
  .modal-dialog {
   max-width: 1000px;
   ;
   margin: 1.75rem 7rem 5rem 7rem
  }
 }
 @media (max-width: 800px) {
  .modal-dialog {
   max-width: 1000px;
   ;
   margin: 1.75rem 7rem 5rem 7rem
  }
 }
 @media (max-width: 576px) {
  .modal-dialog {
   max-width: 1000px;
   ;
   margin: 1.75rem 5rem 5rem 5rem;
  }
 }
 @media (max-width: 320px) {
  .modal-dialog {
   max-width: 1000px;
   ;
   margin: 1.75rem 3.5rem 5rem 3.5rem;
  }
 }
 // @media (min-width: 576px) {
 //  .modal-dialog {
 //   max-width: 1000px;
 //   ;
 //   margin: 1.75rem auto;
 //  }
 // }

}

.modal::-webkit-scrollbar {
 display: none;
}

.closeButton {
 cursor: pointer;
 padding-right: 20px;
 display: flex;
 justify-content: space-between;
 

 p {
  color: #E85F14;
 }
}

.btnAccept{
 display: flex;
 background: #433185;
 color: #fff;
}

.btnAccept:hover {
 display: flex;
 background: #c0bfc7;
 color: #595757;
 transition: all ease .3s;
}

.btnClose{
 display: flex;
 background: none;
 border: 1px solid #433185;
}
.btnClose:hover{
 display: flex;
 background: none;
 border: 1px solid #787096;
}