@import url("https://use.typekit.net/ajt6eeb.css%22>");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  @include zero-sizing;
  box-sizing: border-box;
  // scrollbar-width: none;
}

.footerLink {
  a {
    color: #fff;
  }
}

img {
  max-width: 100%;
}

.border-left {
  border-left: 2px solid #433185;
}

.cursor-pointer {
  cursor: pointer;
}

body {
  @include zero-sizing;
  width: 100vw;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 20px;
  @include outfit;

  @media (max-width: 560px) {
    overflow-x: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
ol {
  @include zero-sizing;
  font-family: "muli", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  font-family: "muli", sans-serif !important;
}

a {
  text-decoration: none;
}

.main-body {
  // background-image: url(../img/svg-lines.png);
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.future-text-wrap {
  @include padding-x-140;
}

.title_1 {
  text-align: left;
  font: normal normal normal 18px/22px Muli;
  letter-spacing: 0px;
  color: #433185;
  opacity: 1;

  @media (max-width: 560px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.title_2 {
  padding-top: 5px;
  font: normal normal 800 38px/54px Muli;
  letter-spacing: 0px;
  color: #38495d;
  opacity: 1;
  margin-bottom: 50px;

  @media (max-width: 1280px) {
    font-size: 32px;
    line-height: 48px;
  }

  @media (max-width: 620px) {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0;
  }
}

.card__ h5 {
  font: normal normal 800 32pt/38pt Muli;
  letter-spacing: 0pt;
  color: #38495d;
  opacity: 1;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 1440px) {
    font-size: 28px;
    line-height: 34px;
  }

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 32px;
  }
}

.card__ p {
  font: normal normal normal 20pt/30pt Muli;
  letter-spacing: 0pt;
  color: #38495d;
  opacity: 0.6;
  font-size: 18px;
}

.card__ span {
  color: var(--unnamed-color-00a28a);
  text-align: left;
  font: normal normal normal 22pt/36pt Muli;
  letter-spacing: 0pt;
  color: #433185;
  opacity: 1;
  cursor: pointer;
  font-size: 15px;
}

.sm_box {
  height: 130pt;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;

  @media (max-width: 1024px) {
    height: 90pt;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.box_content {
  padding-top: 50px;
  margin-bottom: 40px;
  // height: 618pt;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;

  @media (max-width: 560px) {
    padding-top: 0;
  }

  .image-bottom-dashboard {
    width: 80%;
  }
}

.title_3 {
  text-align: center;
  font: normal normal bold 22pt/28pt Muli;
  letter-spacing: 0pt;
  color: #38495d;
  opacity: 1;
  font-size: 13px;
  line-height: 23px;
  margin-top: 10px;
}

.sm_box h1 {
  padding: 64px 15px 9px 10px;

  font-weight: 600;

  @media (max-width: 1440px) {
    font-size: 36px;
  }

  @media (max-width: 1024px) {
    padding: 0;
    font-size: 32px;
  }

  @media (max-width: 620px) {
    font-size: 22px;
  }
}

.sm_box p {
  @media (max-width: 620px) {
    font-size: 12px;
  }
}

.title_4 {
  h3 {
    padding-bottom: 14px;
    text-align: left;
    font: normal normal 800 48pt/72px Muli;
    letter-spacing: 0pt;
    color: #38495d;
    opacity: 1;
    margin-bottom: 30px;

    @media (max-width: 1680px) {
      font-size: 44px;
      line-height: 68px;
      margin-bottom: 20px;
    }

    @media (max-width: 1366px) {
      font-size: 40px;
      line-height: 60px;
    }

    @media (max-width: 1280px) {
      font-size: 34px;
      line-height: 54px;
    }
  }

  p {
    text-align: left;
    font: normal normal normal 14pt/25pt Muli;
    letter-spacing: 0pt;
    color: #38495d;
    opacity: 0.6;
  }
}

// .title_4

.box_1 {
  background: transparent url(../img/onemedhq_images/footer-bg.jpg) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  padding: 60px 110px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  h3 {
    text-align: center;
    font: normal normal bold 22pt/32pt Muli;
    letter-spacing: 0pt;
    color: #ffffff;
    opacity: 1;

    @media (max-width: 1600px) {
      font-size: 18pt;
      line-height: 38pt;
    }

    @media (max-width: 768px) {
      font-size: 12pt;
      line-height: 28pt;
    }
  }

  @media (max-width: 1024px) {
    padding: 40px 85px;
  }

  @media (max-width: 730px) {
    h3 {
      text-align: center;
      font: normal normal bold 13pt/18pt Muli;
      letter-spacing: 0pt;
      color: #ffffff;
      opacity: 1;
    }
  }

  @media (max-width: 690px) {
    padding: 25px 45px;

    h3 {
      text-align: center;
      font: normal normal bold 12pt/18pt Muli;
      letter-spacing: 0pt;
      color: #ffffff;
      opacity: 1;
    }
  }

  @media (max-width: 496px) {
    h3 {
      text-align: center;
      font: normal normal bold 10pt/12pt Muli;
      letter-spacing: 0pt;
      color: #ffffff;
      opacity: 1;
    }
  }
}

.text-btn {
  font-size: 20px;
  line-height: 25px;
  color: $secondary;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 13px;
  }

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 20px;
  }

  svg {
    margin-left: 10px;
    transform: translateX(0);
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    color: $secondary;

    svg {
      transform: translatex(10px);
    }
  }
}

.page-heading {
  font-weight: 500;
  font-size: 34px;
  line-height: 45px;
  text-transform: uppercase;
  color: $dark;
  margin-bottom: 10px;

  @media (max-width: 1440px) {
    font-size: 30px;
    line-height: 50px;
  }

  @media (max-width: 1280px) {
    font-size: 25px;
    line-height: 44px;
  }

  @media (max-width: 820px) {
    font-size: 20px;
    line-height: 40px;
  }

  @media (max-width: 560px) {
    font-size: 18px;
    line-height: 32px;
  }

  &-wrap {
    margin-block: 110px;
    @include padding-x-140;

    @media (max-width: 1024px) {
      margin-block: 65px;
    }
  }
}

.primary-bg {
  padding: 20px;
  background: $primary;
  width: 100%;

  &-heading {
    @include padding-x;
    padding-top: 100px;
    padding-bottom: 40px;

    @media (max-width: 992px) {
      padding-top: 80px;
      padding-bottom: 30px;
    }

    .bg-heading {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      color: $white;

      @media (max-width: 1366px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

.devider {
  height: 1px;
  width: 100%;
  background: $secondary;

  &-wrap {
    position: relative;
    padding-block: 60px;
    @include padding-x;

    @media (max-width: 820px) {
      padding-block: 40px;
    }

    @media (max-width: 560px) {
      padding-block: 30px;
    }
  }
}

.eventAdd {
  label {
    margin-top: 10px;
  }

  input,
  textarea {
    border: 1px solid;
    padding: 4px;
    margin: 3px 0px;
    border-radius: 3px;
  }

  textarea {
    width: 100%;
  }
}

.body_wrap {
  margin-block: 110px;
  @include padding-x-140;

  @media (max-width: 1024px) {
    margin-block: 65px;
  }

  .img_right {
    height: 320px;

    @media (max-width: 1024px) {
      height: auto;
      width: 100%;
    }
  }

  // .col-left-section{
  //     padding-right: 38px;
  // }
}

.detailsOne_ {
  margin-bottom: 0px;
  // height: 232px;
  padding-bottom: 20px;
  /* UI Properties */
  font: normal normal 800 16pt/25pt Muli;
  letter-spacing: 0px;
  color: #38495d;
  opacity: 1;
}

.details_one {
  top: 833px;
  /* UI Properties */
  font: normal normal normal 20px/38px Muli;
  letter-spacing: 0px;
  color: #38495d;
  opacity: 0.6;
}

.details_two {
  // top: 833px;
  padding-top: 20px;
  /* UI Properties */
  font: normal normal normal 20px/38px Muli;
  letter-spacing: 0px;
  color: #38495d;
  opacity: 0.6;
  padding-bottom: 20px;
}

.right_text {
  margin-top: 10px;
  background-color: #f5f5f7;

  .inner_text {
    padding: 20px;
  }

  .inner_text_heading {
    font: normal normal 800 24px/38px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 1;

    @media (max-width: 560px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .inner_text_details {
    padding-top: 10px;
    font: normal normal normal 20px/32px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 0.6;

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

// .img_right {
//     width: 100%;
// }

.bottom_section_orc {
  padding-top: 4%;

  h3 {
    font: normal normal 800 24px/42px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 1;
  }

  .bottom_title_name {
    padding-bottom: 12px;

    h3 {
      font: normal normal 800 36px/42px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }

    .row {
      padding-top: 5px;
    }

    h3 {
      font: normal normal 800 30px/42px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }
  }

  .img-background {
    background: #f5f5f7 0% 0% no-repeat padding-box;
  }

  .title_bottom {
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 40px;
    // justify-content: center;
    // margin-top: 5px;

    h3 {
      font: normal normal 800 24px/42px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }

    p {
      font: normal normal normal 20px/38px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 0.6;
    }

    span {
      color: var(--unnamed-color-00a28a);
      text-align: left;
      font: normal normal normal 22pt/36pt Muli;
      letter-spacing: 0pt;
      color: #433185;
      opacity: 1;
      cursor: pointer;
      font-size: 15px;
    }
  }
}

.box_bottom_orc {
  background: transparent url(../img//techBanner.png) 0% 0% no-repeat
    padding-box;
  opacity: 1;
  height: 144px;
  right: 128px;
  position: absolute;
  z-index: 3;
  top: 53px;

  h3 {
    text-align: center;
    font: normal normal bold 18pt/26pt Muli;
    letter-spacing: 0pt;
    color: #ffffff;
    opacity: 1;
    padding: 45px;
  }
}

.detail_scicat_ {
  h3 {
    /* UI Properties */
    font: normal normal 800 24px/38px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 1;
  }

  p {
    font: normal normal normal 21px/38px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 0.6;
  }
}

.details_scicat_p {
  /* UI Properties */
  font: normal normal normal 21px/38px Muli;
  letter-spacing: 0px;
  color: #38495d;
  opacity: 0.6;
  padding-bottom: 24px;
}

.details_advanicing {
  font: normal normal 800 24px/46px Muli;
  letter-spacing: 0px;
  color: #38495d;
  opacity: 1;
  padding-bottom: 40px;
}

.body_wrap_advancing {
  margin-block: 110px;
  @include padding-x-140;

  @media (max-width: 1024px) {
    margin-block: 65px;
  }

  .detail_Advancing_para {
    opacity: 1;
    padding-bottom: 40px;

    // padding: 40px;
    h3 {
      font: normal normal 800 32px/42px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
      padding-bottom: 20px;

      @media (max-width: 560px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      font: normal normal normal 22px/35px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 0.6;
    }

    span {
      color: var(--unnamed-color-00a28a);
      text-align: left;
      font: normal normal normal 22pt/36pt Muli;
      letter-spacing: 0pt;
      color: #433185;
      opacity: 1;
      cursor: pointer;
      font-size: 15px;
    }
  }
}

.w-75 {
  @media (max-width: 1024px) {
    width: 100% !important;
  }
}

.botton_advancing {
  margin-top: 20px;
  padding: 24px;
  background: #f5f5f7 0% 0% no-repeat padding-box;
  opacity: 1;

  .bottom_heading {
    font: normal normal 800 32px/42px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 1;

    @media (max-width: 560px) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .bottom_wrap_focus {
    padding-top: 10px;
  }

  .circle-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px;

    @media (max-width: 560px) {
      width: 100%;
    }
  }

  .circle {
    width: 100px;
    aspect-ratio: 3/2;
    object-fit: contain;
  }

  .text_circle {
    padding-top: 4px;
    text-align: center;
    font: normal normal bold 12px/20px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 1;

    @media (max-width: 990px) {
      padding-top: 14px;
      text-align: center;
      font: normal normal bold 9px/17px Muli;
      letter-spacing: 2px;
      color: #38495d;
      opacity: 1;
    }
  }
}

.top_section_aboutus {
  margin-top: 74px;
  margin-bottom: 5%;

  .text_area_ {
    h3 {
      font: normal normal 800 38px/64px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }

    p {
      padding-top: 15px;
      font: normal normal normal 20px/36px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 0.6;
    }

    .bottom_section_p {
      background: #f5f5f7;
      margin-top: 20px;

      p {
        padding: 30px;
        font: normal normal 600 23px/32px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 1;
      }
    }
  }
}

.middle_section_aboutus {
  padding-top: 1px;
  padding-bottom: 18px;
  background-color: #f5f5f7;

  .text_area_ {
    h3 {
      font: normal normal 800 32px/44px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }

    p {
      padding-top: 15px;
      font: normal normal normal 14px/28px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 0.6;
    }

    .bottom_section_p {
      margin-top: 12px;

      p {
        font: normal normal 600 16px/32px Muli;
        letter-spacing: 0px;
        opacity: 1;
        padding-left: 4px;
      }
    }
  }

  .banner_section {
    margin-block: 110px;
    @include padding-x-140;

    @media (max-width: 1024px) {
      margin-block: 65px;
    }

    .banner_Image_about {
      position: relative;
      // z-index: -1;

      @media (max-width: 1024px) {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;

        .img-fluid {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .banner_text {
      position: absolute;
      z-index: 1;
      margin-top: -22px;
      margin-left: 0px;
      width: 40%;
      padding: 42px;
      // height: 45%;
      opacity: 0.85;

      @media (max-width: 1640px) {
        margin-top: -24px;
        width: 65%;
      }

      @media (max-width: 1024px) {
        margin-top: -20px;
        width: 70%;
        position: relative;
        z-index: 2;
        padding: 25px;
      }

      @media (max-width: 560px) {
        width: 100%;
      }

      h3 {
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font: normal normal bold 32px/72px Muli;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin-bottom: 10px;

        @media (max-width: 1400px) {
          font: normal normal normal 31px/58px Muli;
        }

        @media (max-width: 1250px) {
          font: normal normal normal 26px/45px Muli;
        }

        @media (max-width: 1200px) {
          font: normal normal normal 22px/36px Muli;
        }

        @media (max-width: 992px) {
          font: normal normal normal 16px/22px Muli;
        }
      }

      p {
        color: var(--unnamed-color-ffffff);
        font: normal normal normal 20px/30px Muli;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.6;

        @media (max-width: 1400px) {
          font: normal normal normal 18px/28px Muli;
        }

        @media (max-width: 1250px) {
          font: normal normal normal 16px/22px Muli;
        }

        @media (max-width: 1200px) {
          font: normal normal normal 18px/22px Muli;
        }

        @media (max-width: 1200px) {
          font: normal normal normal 18px/22px Muli;
        }

        @media (max-width: 992px) {
          font: normal normal normal 16px/28px Muli;
        }

        @media (min-height: 1160px) {
          font: normal normal normal 22px/30px Muli;
        }
      }
    }
  }
}

.middle_section_advantages {
  margin-block: 110px;
  @include padding-x-140;

  @media (max-width: 1024px) {
    margin-block: 65px;
  }

  .text_area_ {
    h3 {
      font: normal normal 800 32px/44px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }

    p {
      font: normal normal normal 18px/28px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 0.6;
    }
  }
}

.bottom_text_section {
  background: #f5f5f7;
  @include padding-x-140;
  padding-bottom: 110px;

  @media (max-width: 1024px) {
    padding-bottom: 65px;
  }

  h3 {
    font: normal normal 800 28px/38px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 1;

    @media (max-width: 560px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  p {
    padding-top: 10px;
    font: normal normal normal 22px/36px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 0.6;

    @media (max-width: 560px) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .img-fluid-top {
    margin-bottom: 5px;
  }
}

.img-fluid {
  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 820px) {
    max-height: 100%;
  }
}

.bottom-section-about {
  @include padding-x-140;
  padding-block: 110px;

  @media (max-width: 1024px) {
    padding-block: 65px;
  }

  p {
    font: normal normal bold 42px/65px Muli;
    letter-spacing: 0px;
    color: #152934;
    opacity: 1;
  }

  .button-section {
    justify-content: center;
    align-items: center;

    .p-2 {
      width: 100%;

      button {
        font-size: 20px;
      }
    }
  }

  .about-us-mission {
    margin-top: 50px;
    margin-bottom: 54px;

    @media (max-width: 560px) {
      margin-top: 0;
    }

    h3 {
      font: normal normal 800 32px/54px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;

      @media (max-width: 560px) {
        font-size: 22px;
        line-height: 32px;
      }
    }

    p {
      font: normal normal normal 18px/36px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 0.6;

      @media (max-width: 560px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  .bottom_section_p {
    background: #f5f5f7;
    margin-top: 20px;

    p {
      padding: 10px;
      font: normal normal 600 18px/30px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }
  }

  .btn_team_flter {
    background-color: #a82582;
  }
}

.filter-team {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
  }

  @media (max-width: 560px) {
    flex-wrap: wrap;
  }
}

.team-filter-head {
  @media (max-width: 768px) {
    p {
      font: normal normal bold 20px/24px Muli;
    }
  }
}

.about_img1 {
  background-image: url(../img/team/1.png);
  height: 300px;
  width: 300px;
  background-repeat: no-repeat;
}

.col20 {
  h5 {
    color: #38495d;
    font: normal normal 800 24px/32px Muli;
    font-family: "muli", sans-serif;
    text-align: left;
  }

  p {
    color: #433185;
    text-align: left;
    font: normal normal normal 16px/26px Muli;
  }

  @media (min-width: 310px) {
    flex: 0 0 auto;
    width: 100%;
  }

  @media (min-width: 524px) {
    flex: 0 0 auto;
    width: 33%;
  }

  @media (min-width: 634px) {
    flex: 0 0 auto;
    width: 33%;
  }

  // @media (min-width: 824px) {
  //     flex: 0 0 auto;
  //     width: 30%;
  // }
  @media (min-width: 825px) {
    flex: 0 0 auto;
    width: 20%;
  }

  @media (max-width: 820px) {
    width: 100%;
  }
}

.banner_head_text {
  padding-block: 70px;
  border-bottom: 2px solid #eeeef1;

  @media (max-width: 1024px) {
    padding-block: 55px;
  }

  @media (max-width: 820px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
  }

  @media (max-width: 620px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.col20_team {
  flex: 0 0 auto;
  width: 20%;

  @media (max-width: 1600px) {
    width: 22%;
  }

  @media (max-width: 1440px) {
    width: 25%;
  }

  @media (max-width: 1200px) {
    width: 30%;
  }

  @media (max-width: 1024px) {
    width: 33%;
  }

  @media (max-width: 560px) {
    width: 50%;
    padding-inline: 8px;
  }
}

.team_img {
  /* max-height: 300px; */
  position: relative;
  /* height: 270px; */
  margin-bottom: 20px;
}

.team_img img {
  max-height: 309px;
  height: 309px;
  width: 100%;

  @media (max-width: 770px) {
    max-height: 226px;
    height: 200px;
    width: 100%;
  }

  @media (max-width: 560px) {
    height: 175px;
  }

  /* margin-bottom: 19px; */
}

.team_text {
  // bottom: 10px;
  // color: red;
  // left: 10px;
  padding-top: 18px;

  p {
    font: normal normal 800 18px/26px Muli;
    letter-spacing: 0px;
    color: #38495d;
    opacity: 1;

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  span {
    font: normal normal normal 12px/26px Muli;
    letter-spacing: 0px;
    color: #7c8085;
    opacity: 1;

    @media (max-width: 560px) {
      font-size: 10px;
      line-height: 18px;
    }
  }
}

.team_text_1 {
  font: normal normal 800 20px/26px Muli;
  letter-spacing: 0px;
  color: #38495d;
  opacity: 1;

  // span {
  //     font: normal normal bold 10px/32px Muli;
  //     letter-spacing: 0px;
  //     color: #38495D;
  //     opacity: 1;
  // }
}

.max-100 {
  max-width: 100%;
}

.footer-wrap {
  .footer-text {
    margin-top: 14px;
    color: var(--unnamed-color-ffffff);
    font: normal normal normal 18px/32px Muli;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0.6;
    width: 85%;
  }

  .header-name {
    color: var(--unnamed-color-00a28a);
    font: normal normal bold 20px/30px Muli;
    letter-spacing: 0px;
    color: #433185;
    opacity: 1;
    padding: 5px;
  }

  .body-name {
    p {
      color: var(--unnamed-color-ffffff);
      font: normal normal normal 16px/22px Muli;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      padding: 4px;
    }
  }

  .search-box {
    position: relative;
    display: inline-block;
    margin: 5px;
    padding: 10px;
    border: 1px solid #fff;
    display: flex;
  }

  .search-box input[type="text"] {
    box-sizing: border-box;
    font-size: 16px;
    margin: 10px;
    border: none;
    width: 84%;
    transition: border-color 0.3s ease-in-out;
    background-color: transparent;
    color: #fff;

    @media (max-width: 1024px) {
      width: 93%;
    }
  }

  .search-box input[type="text"]:focus {
    outline: none;
    border-color: #433185;
  }

  .search-box button[type="submit"] {
    background: #433185;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
  }

  .search-box button[type="submit"]:hover {
    background-color: #433185;
  }

  .search-box button[type="submit"] i {
    margin-left: 5px;
  }
}

.contact_inner {
  position: relative;
}

.contact-form {
  // position: absolute;
  // z-index: 2;
  // margin-top: -86px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 8rem;
  width: 85%;
  padding-left: 35px;
  padding-right: 35px;
  margin: auto;
  border-radius: 10px;
  // @include padding-x-140;

  @media (min-width: 560px) and (max-width: 768px) {
    // margin-top: -55px;
  }

  .address_info_heading {
    background-color: #ffffff;
    padding: 28px;

    .contact_heading {
      h3 {
        margin: 10rem;
        font: normal normal bold 34px/53px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 1;

        @media (max-width: 560px) {
          font-size: 30px;
          line-height: 38px;
        }
      }
    }

    .contact_address {
      h5 {
        font: normal normal normal 16px/22px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 0.4;
      }

      p {
        text-align: left;
        font: normal normal 600 16px/32px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 1;
      }
    }

    .contact_email {
      h5 {
        font: normal normal normal 16px/22px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 0.4;
      }

      p {
        text-align: left;
        font: normal normal 600 16px/32px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 1;
      }
    }

    .contact_Investors {
      h3 {
        font: normal normal 800 26px/36px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 1;
        margin-top: 35px;
        // margin-bottom: 15px;
      }
    }

    .contact_Investors_details {
      h5 {
        font: normal normal bold 18px/26px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 1;
        margin-top: 15px;
      }

      p {
        font: normal normal normal 14px/26px Muli;
        letter-spacing: 0px;
        color: #433185;
        opacity: 1;
      }
    }
  }

  .contact_Investors_details {
    h5 {
      font: normal normal bold 18px/26px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
      margin-top: 15px;
    }

    p {
      font: normal normal normal 14px/26px Muli;
      letter-spacing: 0px;
      color: #433185;
      opacity: 1;
    }
  }

  .contact_email {
    h5 {
      font: normal normal normal 16px/22px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 0.4;
    }

    p {
      text-align: left;
      font: normal normal 600 16px/32px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }
  }

  .contact_address {
    h5 {
      font: normal normal normal 16px/22px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 0.4;
    }

    p {
      text-align: left;
      font: normal normal 600 16px/32px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }
  }

  .contact-field {
    background-color: #ffffff;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 28px;

    .contact-form-sumbit {
      h3 {
        font: normal normal bold 34px/53px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 1;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 560px) {
          font-size: 30px;
          line-height: 38px;
        }
      }
    }
  }

  .contact-form-sumbit {
    h3 {
      font: normal normal bold 34px/53px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;

      @media (max-width: 560px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }

  .form-wrap {
    background: #ffffff;
    padding: 0px;

    label {
      font: normal normal normal 16px/20px Muli;
      letter-spacing: 0px;
      opacity: 1;
    }

    .formtext {
      text-align: start !important;
    }

    .form-control {
      border: none;
      outline: none;
      box-shadow: none;
      background: #f5f7fa 0% 0% no-repeat padding-box;
      padding: 16px 10px !important;
      font-size: 16px;
      // line-height: 6px;
      animation-name: onautofillcancel;
      min-height: auto;
      border: 0;
      transition: all 0.2s linear;

      .btn {
        background: #0a3e68;
        font-size: 18px;
        // line-height: 28px;
        color: #fff;
        border-radius: 6px !important;
      }
    }
  }

  .media-contact {
    margin-top: 84px;

    @media (max-width: 560px) {
      margin-bottom: 85px;
    }

    .media-heading {
      padding: 30px;

      h3 {
        font: normal normal 800 28px/42px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 1;
      }
    }

    .media-contact-details {
      background: #f4f4f4;
      padding: 30px;

      h3 {
        font: normal normal bold 20px/26px Muli;
        letter-spacing: 0px;
        color: #38495d;
        opacity: 1;
      }

      p {
        color: var(--unnamed-color-00a28a);
        text-align: left;
        font: normal normal normal 14px/26px Muli;
        letter-spacing: 0px;
        color: #433185;
        opacity: 1;
      }

      .media-head-subtitle {
        h3 {
          font: normal normal normal 16px/22px Muli;
          letter-spacing: 0px;
          color: #38495d;
          opacity: 0.6;
        }

        p {
          font: normal normal 600 18px/32px Muli;
          letter-spacing: 0px;
          color: #38495d;
          opacity: 1;
        }
      }
    }
  }
}

.top-advantage-section {
  margin-top: 35px;

  .text_area_ {
    h3 {
      font: normal normal 800 32px/44px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 1;
    }

    p {
      padding-top: 15px;
      font: normal normal normal 14px/28px Muli;
      letter-spacing: 0px;
      color: #38495d;
      opacity: 0.6;
    }
  }
}

.future-statement {
  margin-block: 110px;

  @media (max-width: 1024px) {
    margin-block: 65px;
  }

  @media (max-width: 560px) {
    margin-block: 0;
  }
}

.power-generation-info-wrap {
  padding-block: 110px;
  @include padding-x-140;

  @media (max-width: 1024px) {
    padding-block: 65px;
  }
}

.power-numbering {
  padding-bottom: 80px;
  border-bottom: 2px solid #eeeef1;
  margin-bottom: 80px;

  @media (max-width: 820px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;

    .aos-init {
      width: 100%;
    }
  }

  @media (max-width: 560px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.project-body {
  @include padding-x-140;
  padding-block: 110px;
  background: #f4f4f4;

  @media (max-width: 1024px) {
    padding-block: 65px;
  }
}

.formInput {
  padding: 8px !important;
  padding-left: 14px !important;
  border: 1px solid rgb(211, 201, 201) !important;
  // background: rgb(204, 232, 255) !important;
  border-radius: 4px !important;
}

.map-container iframe {
  width: 100%;
  height: 18em;
}
