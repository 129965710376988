.modalOne{
 display: block !important;
  // pointer-events: none;
  position: fixed;
  background: #000000ed;
  z-index: 999;
  width: 100vw;;
  bottom: 0;
  // height: 100px;
  left: 0;
  color: #fff;
  justify-content: center;
}

.cookieOneBody{
 padding-top: 2%;
}

.anchorCookie{
//  text-decoration: underline;
 cursor: pointer;
 color: #ff0000;
}
.anchorCookie:hover{
 cursor: pointer;
 color: #b9a6f5;
}

.bodyCookie{
 display: flex;
 justify-content: space-around;
 align-self: center;
 margin-bottom: 30px;

  @media (max-width: 780px) {
   padding: 15px;
   display: block;
   margin-bottom: 5px;
   }
}

.bodyCookietxt{
 align-self: center;
}

.bodyCookiebtn{
  display: flex;
 align-self: center;
 @media (max-width: 1200px) {
    display: block;
    align-self: center;
  }

 @media (max-width: 780px) {
    text-align: center;
  }
}

.rejectAll{
  margin-left: 4px;
  @media (max-width: 1200px) {
      margin-top: 5px;
      margin-left: 0px;
    }
}
.acceptAll{
  margin-right: 4px;
  @media (max-width: 1200px) {
      margin-right: 0px;
    }
}

.cookieOneBtn{
 background: #8468eb;
 color: #fff;
 padding: 8px 16px;
 border-radius: 0;
}
.cookieOneBtn:hover{
 background: #8468eb;
 color: #fff;
 padding: 8px 16px;
}

