.bottom {
  &-section {
    @include padding-x;
    padding-block: 140px;

    @media (max-width: 820px) {
      padding-block: 100px;
    }
  }

  &-card {
    width: 100%;
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $secondary;
    position: relative;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    @media (max-width: 1600px) {
      height: 300px;
    }

    @media (max-width: 992px) {
      height: 230px;
    }

    @media (max-width: 820px) {
      height: 195px;
    }

    .card-shape {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: color-burn;
    }

    &:hover {
      background-color: rgba($color: $primary, $alpha: 0.6);

      .icon-btn {
        transform: rotate(30deg);
      }
    }
  }
}

.card-text {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 52px;
  text-align: center;
  color: #ffffff;
  position: relative;

  @media (max-width: 992px) {
    font-size: 26px;
    line-height: 32px;
  }

  @media (max-width: 820px) {
    font-size: 22px;
    line-height: 29px;
  }
}

.icon-btn {
  transform: rotate(0);
  transition: all 0.5s ease;
}
